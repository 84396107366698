import { Description } from '@gimlite/watermelon/components/description/description.component';
import { Empty } from '@gimlite/watermelon/components/empty/empty.component';
import { Popover } from '@gimlite/watermelon/components/popover/popover.component';
import { SkeletonBlock } from '@gimlite/watermelon/components/skeleton/skeleton.component';
import { getCSS } from '@gimlite/watermelon/functions/css.function';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Period } from '../../../client/graphql';
import './cover.component.scss';
dayjs.extend(duration as any);

type CoverProps = {
  className?: string;
  data?: {
    session?: {
      startDate?: string | null;
      endDate?: string | null;
    };
    covers?: Period[];
  };
};

const cellule = {
  width: 50,
  gap: 2,
};

const Cover = ({
  className = '',
  data: { covers = undefined, session = undefined } = {},
}: CoverProps) => {
  const [numberOfColumns, setNumberOfColumns]: any = useState([]);
  const coverHTML = useRef(null);

  useEffect(() => {
    if (session && covers) {
      const calcNumberOfColumns = () => {
        const currentCoverHTML: any = coverHTML?.current;
        if (currentCoverHTML)
          setNumberOfColumns(
            () =>
              Math.floor(
                currentCoverHTML?.getBoundingClientRect().width /
                  cellule.width || 1,
              ) - 1,
          );
      };

      calcNumberOfColumns();
      window.addEventListener('resize', calcNumberOfColumns);
      return () => window.removeEventListener('resize', calcNumberOfColumns);
    }
  }, [coverHTML, session, covers]);

  const stepOfTime = useMemo(() => {
    if (numberOfColumns && covers && session) {
      const endDate = dayjs(session.endDate);
      const startDate = dayjs(session.startDate);
      const stepInMs = endDate.diff(startDate) / numberOfColumns;
      return stepInMs;
    } else {
      return 0;
    }
  }, [session, covers, numberOfColumns]);

  const formattedTimeline = useCallback(() => {
    if (stepOfTime && session && covers) {
      const startDate = dayjs(session.startDate);

      let currentDay = startDate.format('DD/MM/YYYY');
      const buildTimelineData = [startDate.format('DD/MM HH:mm')];

      for (let i = 1; i < numberOfColumns + 1; i++) {
        const incrementTime = startDate.add(stepOfTime * i, 'ms');
        const isDifferentDay =
          currentDay !== incrementTime.format('DD/MM/YYYY');
        currentDay = incrementTime.format('DD/MM/YYYY');

        buildTimelineData.push(
          isDifferentDay || i === numberOfColumns
            ? incrementTime.format('DD/MM HH:mm')
            : incrementTime.format('HH:mm'),
        );
      }

      return (
        <div
          style={{
            gridTemplateColumns: `${
              cellule.width / 2
            }px repeat(${numberOfColumns}, 1fr) ${cellule.width / 2}px`,
            gridTemplateRows: `1fr`,
            gap: `0 ${cellule.gap}px`,
          }}
          className="cover__timeline"
        >
          {['', ...buildTimelineData, ''].map(
            (value: string, index: number) => (
              <div
                style={{
                  transform: `translateX(calc(-50% + ${cellule.gap / 2}px))`,
                }}
                key={'cover-timeline-item' + index}
                className="cover__timeline__item"
              >
                <span>{value}</span>
              </div>
            ),
          )}
        </div>
      );
    } else {
      return <></>;
    }
  }, [covers, session, numberOfColumns]);

  const formattedCover = useCallback(() => {
    if (covers && session && session?.startDate && session?.endDate) {
      return (
        <div
          style={{
            gap: `0 ${cellule.gap}px`,
            gridTemplateRows: `repeat(${covers.length}, 1fr)`,
          }}
          className="cover__contain"
          data-testid="covers"
        >
          {covers.map((cover, indexLine) => (
            <div
              key={'cover-contain-line' + indexLine}
              style={{
                gridTemplateColumns: `${
                  cellule.width / 2
                }px repeat(${numberOfColumns}, 1fr) ${cellule.width / 2}px`,

                gap: `0 ${cellule.gap}px`,
              }}
              className={`cover__contain__line`}
            >
              {Array(numberOfColumns + 2)
                .fill('n')
                .map((_: any, indexLineItem: number) => (
                  <div
                    key={indexLine + 'cover-contain-line-back' + indexLineItem}
                    className={`cover__contain__line__back cover__contain__line__back--${
                      indexLine % 2 === 0 ? 'odd' : 'even'
                    }`}
                  ></div>
                ))}
              <div
                style={{
                  padding: `0 ${cellule.width / 2}px`,
                  width: `calc(100% - ${cellule.width}px)`,
                }}
                className="cover__contain__line__range"
              >
                <div className="cover__contain__line__range__contain">
                  <Progress
                    session={{
                      startDate: session.startDate!,
                      endDate: session.endDate!,
                    }}
                    progress={{
                      startDate: cover.startDate,
                      endDate: cover.endDate,
                    }}
                    type={cover.coverType}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return <></>;
    }
  }, [covers, session, numberOfColumns]);

  if (!covers || !session) {
    return <SkeletonBlock />;
  }

  if (
    covers &&
    session &&
    (Object.entries(session).length !== 2 || covers.length == 0)
  ) {
    return (
      <div className="cover-empty">
        <Empty />
      </div>
    );
  }

  return (
    <div ref={coverHTML} className={`cover ${className}`}>
      {formattedTimeline()}
      {formattedCover()}
    </div>
  );
};

export const coverAuthorizedType = [
  'TOLERANCE_PERIOD',
  'FREE_PERIOD',
  'SHORT_TERM',
  'SUBSCRIPTION',
] as const satisfies string[];

export declare namespace ProgressType {
  type Props = {
    progress: {
      startDate: Date | string;
      endDate: Date | string;
    };
    session: {
      startDate: Date | string;
      endDate: Date | string;
    };
    type: (typeof coverAuthorizedType)[number];
  };
}

const Progress = ({ session, progress, type }: ProgressType.Props) => {
  const intrepretCoverType = {
    TOLERANCE_PERIOD: {
      color: getCSS('--color-warn'),
      text: 'tolerance',
    },
    FREE_PERIOD: {
      color: getCSS('--color-primary'),
      text: 'freeTime',
    },
    SHORT_TERM: {
      text: 'rightInTime',
      color: getCSS('--color-success'),
    },
    SUBSCRIPTION: {
      text: 'subscription',
      color: getCSS('--color-success'),
    },
  } as const satisfies Record<
    ProgressType.Props['type'],
    {
      text: string;
      color: string;
    }
  >;

  const { t } = useTranslation();
  const labelHTML = useRef<HTMLSpanElement>(null);
  const progressHTML = useRef<HTMLDivElement>(null);

  const [percentProgress, setPercentProgress] = useState(0);
  const [textProgress, setTextProgress] = useState('');
  const [gapProgress, setGapProgress] = useState(0);
  const [showDurationInline, setShowDurationInline] = useState(true);
  const [widthLabel, setWidthLabel] = useState<number | null>(null);
  const [widthProgress, setWidthProgress] = useState<number | null>(null);

  const endDateSession = dayjs(session.endDate);
  const startDateSession = dayjs(session.startDate);
  const intervalSession = endDateSession.diff(startDateSession);
  const endDateProgress = dayjs(progress.endDate);
  const startDateProgress = dayjs(progress.startDate);
  const intervalProgress = endDateProgress.diff(startDateProgress);

  const widthContain = useMemo((): number | null => {
    if (!widthProgress || !widthLabel) return null;

    return widthLabel
      ? Math.max((percentProgress / 100) * widthProgress, widthLabel)
      : null;
  }, [percentProgress, widthLabel, widthProgress]);

  const marginLeftContain = useMemo((): number | null => {
    if (!widthProgress || !widthContain) return null;

    return widthProgress - (gapProgress / 100) * widthProgress < widthContain
      ? 100 - (widthContain / widthProgress) * 100
      : gapProgress;
  }, [widthProgress, gapProgress, widthContain]);

  useEffect(() => {
    setPercentProgress(() => (intervalProgress / intervalSession) * 100);
    setTextProgress(() => dayjs.duration(intervalProgress).format('HH:mm'));
    setGapProgress(
      () => (startDateProgress.diff(startDateSession) / intervalSession) * 100,
    );
  }, [session, progress]);

  useEffect(() => {
    const currentProgressHTML: any = progressHTML?.current;
    if (currentProgressHTML) {
      const widthOfDurationBlock = currentProgressHTML?.querySelector(
        '.progress__contain__duration',
      )?.offsetWidth;

      const widthOfDurationText = currentProgressHTML?.querySelector(
        '.progress__contain__duration__item',
      )?.offsetWidth;

      if (
        typeof widthOfDurationBlock === 'number' &&
        typeof widthOfDurationBlock === 'number'
      ) {
        setShowDurationInline(
          () => widthOfDurationBlock + 10 > widthOfDurationText,
        );
      }
    }
  }, [progressHTML]);

  useEffect(() => {
    if (!labelHTML.current) return;

    const observer = new ResizeObserver(([entry]) => {
      setWidthLabel(entry.contentRect.width);
    });

    observer.observe(labelHTML.current);

    return () => observer.disconnect();
  }, [labelHTML]);

  useEffect(() => {
    if (!progressHTML.current) return;

    const observer = new ResizeObserver(([entry]) => {
      setWidthProgress(entry.contentRect.width);
    });

    observer.observe(progressHTML.current);

    return () => observer.disconnect();
  }, [progressHTML]);

  return (
    <div ref={progressHTML} className="progress">
      <div
        style={{
          width: widthContain ? widthContain : `initial`,
          marginLeft: marginLeftContain ? `${marginLeftContain}%` : `initial`,
          justifyContent: 'center',
        }}
        className="progress__type"
      >
        <span ref={labelHTML}>
          {t(`${intrepretCoverType?.[type]?.text}`)} - {textProgress}
        </span>
      </div>

      <Popover
        className="progress__popover"
        config={{ trigger: 'hover', placement: 'top' }}
        data={
          <Description
            config={{ layout: 'column' }}
            data={[
              {
                key: 'startDate',
                label: t('startDate'),
                value: `${startDateProgress.format('DD/MM/YYYY HH:mm:ss')}`,
              },
              {
                key: 'endDate',
                label: t('endDate'),
                value: `${endDateProgress.format('DD/MM/YYYY HH:mm:ss')}`,
              },
              {
                key: 'duration',
                label: t('duration'),
                value: `${dayjs
                  .duration(intervalProgress)
                  .format('D')} jours ${dayjs
                  .duration(intervalProgress)
                  .format('HH:mm:ss')}`,
              },
              {
                key: 'coverage',
                label: t('coverage'),
                value: t(`${intrepretCoverType?.[type]?.text}`),
              },
            ]}
          />
        }
      >
        <div className="progress__contain">
          <div
            style={{
              marginLeft: `${gapProgress}%`,
              width: `${percentProgress}%`,
              backgroundColor: intrepretCoverType?.[type]?.color,
            }}
            className="progress__contain__duration"
          >
            {showDurationInline && (
              <span className="progress__contain__duration__item">
                {/* {textProgress} */}
              </span>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
};

export { Cover };
