import { Languages, useLocalStorage, useRootStore } from '@gimlite/watermelon';
import IEM from '@gimlite/watermelon/assets/logo/IEM_small';
import { Main } from '@gimlite/watermelon/components/main/main.component';
import {
  endOfDay,
  yesterday,
} from '@gimlite/watermelon/functions/date.function';
import { toCapitalizeCase } from '@gimlite/watermelon/functions/string.function';
import { useMyUrl } from '@gimlite/watermelon/hook/useMyUrl.hook';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Query } from '../client/graphql';
import LogoIEMPrestoScan from './assets/logo/iem-prestoscan';
import { truncateString } from './common/fun/string';

const MainUse = observer(() => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { buildParamsUrl } = useMyUrl();
  const { t, lang }: any = useTranslation();
  const { AuthStore, I18NStore, MenuStore, GQLStore } = useRootStore();
  const [minUncoveredDuration] = useLocalStorage(
    'minUncoveredDuration',
    undefined,
  );

  const me = AuthStore.user as Query['me'];

  const eventsDefaultParams = {
    startDate: yesterday('DATE'),
    endDate: endOfDay('DATE'),
    cameraId: 'all',
    way: 'all',
    knownLpn: 'yes',
  };

  useEffect(() => {
    if (AuthStore.token && GQLStore.client && GQLStore.introspection) {
      AuthStore.setMe({
        client: GQLStore.client,
        introspection: GQLStore.introspection,
      });
    }
  }, [AuthStore.token, GQLStore.client, GQLStore.introspection]);

  useEffect(() => {
    if (me) {
      const { ups, lang } = me;

      if (pathname === '/' && ups)
        navigate(
          `/${ups[0]?._id}/events?${buildParamsUrl(eventsDefaultParams)}`,
        );
    }
  }, [me, eventsDefaultParams]);

  useEffect(() => {
    if (me) {
      const { ups, lang } = me;

      I18NStore.updateLang(lang as Languages);
    }
  }, [me]);

  useEffect(() => {
    if (me) {
      AuthStore.setAccount({
        firstName: me.firstName || '',
        lastName: me.lastName || '',
        email: me.username || '',
      });
    }
  }, [me]);

  useEffect(() => {
    if (me && me.ups) {
      const { ups } = me;

      const multipleCities =
        [...new Set(ups.map((ups) => ups?.address?.postalCode).filter(Boolean))]
          .length > 1;

      const vaoDefaultParams = {
        minUncoveredDuration: minUncoveredDuration,
      };

      MenuStore.setMenu([
        {
          key: 'top',
          position: 'top',
          title: `${t('allParkingLots')} (${ups.length})`,
          items: ups
            .map(({ _id, address, ...rest }) => ({
              _id,
              address,
              translate: truncateString(
                multipleCities && address?.addressLocality
                  ? `${address?.addressLocality} - ${t(`ups-${_id}-name`)}`
                  : t(`ups-${_id}-name`),
                27,
              ),
              ...rest,
            }))
            .sort((a, b) =>
              a.translate
                .toLocaleLowerCase()
                .localeCompare(b.translate.toLocaleLowerCase()),
            )
            .map(({ _id, translate, config }) => {
              const vaoParams = {
                ...vaoDefaultParams,
                maxSessionDurationInHours:
                  config?.prestoScan?.maxSessionDurationInHours ?? undefined,
              };
              const items: any[] = [
                {
                  key: `/${_id}/events`,
                  params: eventsDefaultParams,
                  label: toCapitalizeCase(t('entries/exits')),
                  icon: 'faCarSolid',
                },
                {
                  key: `/${_id}/vao`,
                  params: vaoParams,
                  label: `${t('vao')}`.toUpperCase(),
                  icon: 'faCameraSolid',
                },
              ];

              // if (config?.prestoScan?.canShowOccupancy)items.push({
              //   key: `/${_id}/occupancy`,
              //   label: `${t('occupancy')}`.toUpperCase(),
              //   icon: <PieChartFilled style={{ fontSize: '24px' }} />,
              // });

              return {
                key: `/${_id}/events`,
                label: translate,
                icon: 'faSquareParkingSolid',
                items,
              };
            }),
        },
      ]);
    }
  }, [me, lang, minUncoveredDuration]);

  return me ? (
    <Main
      handleEvent={{
        selected: (key: string) => {
          navigate(key);
        },
      }}
      config={{
        navigation: {
          logo: LogoIEMPrestoScan(),
          mode: 'drop',
        },
        //! Lors d'une refact du router, ce code apparaitra une fois en global (PROBLEM 1)
        //******* START ************/
        header: {
          logo: IEM(),
        },
        //******* STOP ************/
      }}
    />
  ) : null;
});

export default MainUse;
